import { Country, State ,City } from "country-state-city";
export const GetAllCountryCityStates = {
    data() {
        return {
            stateList: [],
            citiesList:[],
            countryList: []
        };
    },
    methods: {
        getAllCountriesMethod() {
            this.countryList = Country.getAllCountries()
        },
        getAllStatesMethod(country_name) {
            this.stateList = State.getStatesOfCountry(this.countryList.filter(country=>country.name == country_name)[0].isoCode)
            
        },
        getAllCitiesMethod(state_name) {
            let current_state = this.stateList.filter(state=>state.name==state_name)[0]
            this.citiesList = City.getCitiesOfState(current_state.countryCode,current_state.isoCode)
        }

    },
};
